import React, { memo } from 'react';
import { graphql } from 'gatsby';
import { MainPage } from '../templates/MainPage';
import SeoText from 'components/SeoText';
import { Helmet } from "react-helmet"

const SemkaBlogov = () => {
  return (
    <>
      <Helmet>
        <title>Съемки блогов цена в Москве услуги от студии Elandic</title>
        <meta name="description" content="Съемка блогов цена в Москве услуги под ключ на сайте студии Elandic. Заказать профессиональные услуги записи видео блога стоимость от видеопродакшена." />
        <meta name="keywords" content="съемки блогов, запись, видео блога, цена, москва" />
      </Helmet>
      <MainPage>
        <SeoText title='Съемки блогов'>
          <p>Блог – это не просто хобби, это площадка для выражения себя, построения бренда и общения с аудиторией. Elandic - видеопродакшн, специализирующийся на создании контента для блогеров в Москве. Мы поможем вам превратить ваш блог в захватывающее видео путешествие, которое будет привлекать новых подписчиков и удерживать внимание старых.</p>

          <p>Elandic – это ваш надежный партнер в сфере видеосъемки блогов. Мы обладаем глубокими знаниями в области видеопродакшена и создании качественного контента для различных форматов блога. Мы считаем, что каждый блог уникален. Поэтому мы внимательно изучаем ваши пожелания, специфику вашего блога и целевую аудиторию, чтобы создать видео, которое будет отражать вашу индивидуальность и привлекать ваших зрителей. </p>

          <p>Мы не боимся экспериментировать и искать новые решения, чтобы сделать ваши видео еще более запоминающимися и яркими. Мы используем профессиональное оборудование и программное обеспечение, чтобы обеспечить отличное качество съемки, монтажа и звучания вашего видео. Мы предлагаем разные варианты услуг и цен в зависимости от ваших потребностей, чтобы вы могли выбрать оптимальный вариант для своего бюджета.</p>

          <p>Стоимость услуг по видеосъемке блогов в Москве от Elandic зависит от многих факторов: длительность видео, сложность сценария, количество съемочных дней, использование дополнительных услуг.</p>

          <p>С нами вы получите яркие и интересные видеоролики, которые будут отражать стиль вашего блога, профессионально снятые и смонтированные видео, создающие качественное и привлекательное впечатление, эффективные видео, которые привлекут внимание новой аудитории и удержать интерес старых подписчиков.</p>

          <p>Elandic – это ваш выбор для создания блога в Москве. Мы поможем вам оживить ваш контент, привлечь новых подписчиков и увеличить взаимодействие с аудиторией. Свяжитесь с нами, чтобы обсудить ваши идеи и получить индивидуальное предложение по видеосъемке. </p>

        </SeoText>
      </MainPage>
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["mainPage", "common", "layout"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default memo(SemkaBlogov);
